import React, { useMemo } from 'react';
import { DataGridPro, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

// 🛠️ Utility to aggregate data by product name
const aggregateEventUsage = (events, unitsPlanted) => {
    const aggregatedData = {};

    // Process each event type explicitly
    ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'].forEach((eventTypeKey) => {
        (events[eventTypeKey] || []).forEach((record) => {
            const eventType = eventTypeKey.replace('Events', '');
            const productName = record[`${eventType}_name`] || 'N/A';
            const unitType = record.amt_used_unit_type || 'N/A';
            const amtUsed = record.amt_used || 0;

            if (!aggregatedData[productName]) {
                aggregatedData[productName] = {
                    product_name: productName,
                    amt_used: 0,
                    amt_used_unit_type: unitType,
                    per_plant: 0,
                };
            }

            aggregatedData[productName].amt_used += amtUsed;
        });
    });

    // Calculate per-plant usage
    Object.keys(aggregatedData).forEach((key) => {
        aggregatedData[key].per_plant = unitsPlanted
            ? (aggregatedData[key].amt_used / unitsPlanted).toFixed(4)
            : 'N/A';
    });
    console.log(aggregatedData)
    return Object.values(aggregatedData);
};

const EventUsageSummary = ({ events = {}, unitsPlanted = 1 }) => {
    const aggregatedRows = useMemo(() => aggregateEventUsage(events, unitsPlanted), [events, unitsPlanted]);

    const columns = [
        { field: 'product_name', headerName: 'Product Name', flex: 1 },
        { field: 'amt_used', headerName: 'Amount Used', flex: 1 },
        { field: 'amt_used_unit_type', headerName: 'Unit Type', flex: 1 },
        { field: 'per_plant', headerName: 'Per Plant', flex: 1 },
    ];

    return (
        <Box sx={{ p: 2, bgcolor: '#1e1e1e', borderRadius: '8px', height: '100%', width: '100%', overflow: 'auto'}}>
            <DataGridPro
                rows={aggregatedRows.map((row, index) => ({ id: index, ...row }))}
                columns={columns}
                slots={{ toolbar: GridToolbarQuickFilter }}
                disableRowSelectionOnClick
                pagination
                sx={{
                    bgcolor: '#121212',
                    color: '#ffffff',
                    '& .MuiDataGrid-columnHeaders': {
                        bgcolor: '#1e1e1e',
                    },
                }}
            />
        </Box>
    );
};

export default EventUsageSummary;