import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom';
import FarmsNav from './FarmsNav';
import FarmSideBar from './FarmSideBar';
import ZoneDrawer from './ZoneDrawer';
import { useFarmData } from '../context/FarmDataContext';

const FarmPageLayout = () => {
    const { farm_id } = useParams();
    const location = useLocation();
    const [menuExpanded, setMenuExpanded] = useState(false);
    const [drawerExpanded, setDrawerExpanded] = useState(true);

    const { farmData, fetchFarmData, loading } = useFarmData();
    const zones = location.state?.zones || [];
    const userData = location.state?.userData;
    const idToken = location.state?.idToken;
    const farmLocations = location.state?.farmLocations;

    const isMobile = useMediaQuery('(max-width: 600px)'); // Detect mobile screens

    useEffect(() => {
        if (idToken && farm_id) {
            if (!farmData || farmData.farmDetails?.id !== farm_id) {
                fetchFarmData(farm_id, idToken);
            }
        }
    }, [idToken, farm_id, farmData, fetchFarmData]);

    if (loading || !farmData) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                bgcolor="#f9f9f9"
            >
                <CircularProgress />
            </Box>
        );
    }

    const { crops = [], harvests = [] } = farmData;
    const isFarmDetailsPage = location.pathname.startsWith(`/farm/${farm_id}`);

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            {/* Sidebar for Desktop */}
            {!isMobile ? (
                <Box
                    sx={{
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: menuExpanded ? '200px' : '80px',
                        transition: 'width 0.3s',
                        zIndex: 1201,
                        backgroundColor: '#333',
                    }}
                >
                    <FarmSideBar
                        idToken={idToken}
                        userData={userData}
                        menuExpanded={menuExpanded}
                        setMenuExpanded={setMenuExpanded}
                        zones={zones}
                        farm_id={farm_id}
                    />
                </Box>
            ) : (
                /* Bottom Navigation for Mobile */
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1201,
                        backgroundColor: '#333',
                    }}
                >
                    <FarmSideBar
                        idToken={idToken}
                        userData={userData}
                        menuExpanded={false} // No expansion logic for bottom nav
                        setMenuExpanded={() => {}} // Disabled toggle
                        zones={zones}
                        farm_id={farm_id}
                    />
                </Box>
            )}

            {/* Main Content Wrapper */}
            <Box
                sx={{
                    marginLeft: !isMobile ? (menuExpanded ? '200px' : '80px') : '0px',
                    marginRight: isFarmDetailsPage && !isMobile ? (drawerExpanded ? '300px' : '80px') : '0px',
                    width: `calc(100% - ${
                        !isMobile
                            ? menuExpanded
                                ? '200px'
                                : '80px'
                            : '0px'
                    } - ${
                        isFarmDetailsPage && !isMobile ? (drawerExpanded ? '300px' : '80px') : '0px'
                    })`,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    transition: 'margin-left 0.3s, margin-right 0.3s, width 0.3s',
                }}
            >
                {/* Top Navigation */}
                <Box
                    sx={{
                        flexShrink: 0,
                        width: '100%',
                        zIndex: 1200,
                        position: 'sticky',
                        top: 0,
                        padding: 1,
                    }}
                >
                    <FarmsNav
                        farmLocations={farmLocations}
                        farm_id={farm_id}
                        idToken={idToken}
                    />
                </Box>

                {/* Main Content Area */}
                <Box
                    component="main"
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        padding: '16px',
                        overflow: 'auto',
                    }}
                >
                    <Outlet context={{ userData, idToken, farm_id, zones }} />
                </Box>
            </Box>

            {/* Right Sidebar (ZoneDrawer) - Only on Non-Mobile */}
            {!isMobile && isFarmDetailsPage && (
                <Box
                    sx={{
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: drawerExpanded ? '300px' : '80px',
                        zIndex: 1201,
                        backgroundColor: '#444',
                        transition: 'width 0.3s',
                        overflowY: 'auto',
                    }}
                >
                    <ZoneDrawer
                        zones={zones}
                        crops={crops}
                        harvests={harvests}
                        drawerExpanded={drawerExpanded}
                        setDrawerExpanded={setDrawerExpanded}
                    />
                </Box>
            )}
        </Box>
    );
};

export default FarmPageLayout;