import React, { useMemo, useState } from 'react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
} from 'recharts';
import { Box, Typography, TextField, Select, MenuItem } from '@mui/material';

const InputPricesChart = ({ inputs }) => {
    const [selectedInputType, setSelectedInputType] = useState('FERTILIZERS'); // Default to FERTILIZERS
    const [searchQuery, setSearchQuery] = useState('');

    // Get unique input types for the dropdown
    const inputTypes = useMemo(
        () =>
            Array.from(new Set(inputs.map((input) => input.input_type.toUpperCase()))).sort(),
        [inputs]
    );

    // Process data for the chart
    const chartData = useMemo(() => {
        const filteredInputs = inputs.filter(
            (input) =>
                input.input_type.toUpperCase() === selectedInputType &&
                input.product_name
                    ?.toLowerCase()
                    .includes(searchQuery.toLowerCase())
        );

        const groupedData = filteredInputs.reduce((acc, input) => {
            const date = new Date(input.purchase_date); // Use purchase_date for the date axis
            const monthYear = `${date.getFullYear()}-${String(
                date.getMonth() + 1
            ).padStart(2, '0')}`;

            if (!acc[monthYear]) {
                acc[monthYear] = { monthYear, totalUnitPrice: 0, count: 0 };
            }

            acc[monthYear].totalUnitPrice += input.unit_price || 0;
            acc[monthYear].count += 1;

            return acc;
        }, {});

        // Calculate averages and format data for the chart
        return Object.values(groupedData).map(({ monthYear, totalUnitPrice, count }) => ({
            monthYear,
            averagePrice: (totalUnitPrice / count).toFixed(2),
        }));
    }, [inputs, selectedInputType, searchQuery]);

    // Determine Y-axis max value
    const maxAveragePrice = useMemo(
        () => Math.max(...chartData.map((d) => parseFloat(d.averagePrice) || 0)),
        [chartData]
    );

    return (
        <Box sx={{ backgroundColor: '#1e1e1e', padding: 2, borderRadius: 4 }}>
            <Typography variant="h6" color="white" mb={2}>
                Input Prices by Type
            </Typography>
            <div className="row mb-3">
                {/* Input Type Dropdown */}
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <Select
                        value={selectedInputType}
                        onChange={(e) => setSelectedInputType(e.target.value)}
                        sx={{
                            color: 'white',
                            backgroundColor: '#333',
                            borderRadius: 2,
                            width: '100%',
                        }}
                    >
                        {inputTypes.map((inputType) => (
                            <MenuItem key={inputType} value={inputType}>
                                {inputType}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                {/* Search Bar */}
                <div className="col-12 col-md-6">
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search by product name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{
                            backgroundColor: '#333',
                            color: 'white',
                            input: { color: 'white' },
                        }}
                    />
                </div>
            </div>
            {/* Line Chart */}
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 60 }} // Adjust margins to prevent bleeding
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                    <XAxis
                        dataKey="monthYear"
                        stroke="#fff"
                        tick={{ fill: '#ccc', fontSize: 12 }}
                        angle={-45} // Tilt labels
                        textAnchor="end"
                        interval={0} // Ensure all labels are displayed
                    />
                    <YAxis
                        stroke="#fff"
                        domain={[0, maxAveragePrice + maxAveragePrice * 0.1]} // Scale Y-axis with 10% buffer
                        tick={{ fill: '#ccc', fontSize: 12 }}
                        label={{
                            value: 'Average Price (NGN)',
                            angle: -90,
                            position: 'insideLeft', // Position the label inside the chart
                            textAnchor: 'middle',  // Center-align the text
                            dx: -10,              // Adjust horizontal position slightly left
                            dy: 50,                // Keep vertical position centered
                            fill: '#ccc',         // Label color
                            fontSize: 14,         // Optional: Adjust font size for better visibility
                        }}
                    />

                    <Tooltip
                        formatter={(value) => `${value} NGN`}
                        contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                    />
                    <Line
                        type="monotone"
                        dataKey="averagePrice"
                        stroke="#ff7300"
                        name="Average Unit Price"
                        strokeWidth={2}
                        dot={{ r: 4 }}
                        activeDot={{ r: 6 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default InputPricesChart;