import React, { useState, useEffect } from "react";
import {
    AppBar,
    Box,
    IconButton,
    Avatar,
    Tooltip,
    Typography,
    Menu,
    MenuItem,
    BottomNavigation,
    BottomNavigationAction,
    useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MapIcon from "@mui/icons-material/Map";
import SpaIcon from "@mui/icons-material/Spa";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useNavigate } from "react-router-dom";

const FarmSideBar = ({
    onLogout,
    idToken,
    userData = {},
    menuExpanded,
    setMenuExpanded,
    farm_id,
    zones = [],
    farmLocations = [],
}) => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate();
    const [selectedFarm, setSelectedFarm] = useState(null);
    const [bottomNavValue, setBottomNavValue] = useState(1);

    const isMobile = useMediaQuery("(max-width: 600px)");

    useEffect(() => {
        const farm = farmLocations?.find((f) => f.id === farm_id);
        setSelectedFarm(farm || null);
    }, [farm_id, farmLocations]);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuToggle = () => {
        setMenuExpanded(!menuExpanded);
    };

    const handleCopyIdToken = async () => {
        try {
            await navigator.clipboard.writeText(`Bearer ${idToken}`);
            alert("ID Token copied to clipboard");
        } catch (err) {
            console.error("Failed to copy ID token:", err);
        }
    };

    const handleNavigation = (path) => {
        if (farm_id) {
            navigate(path, {
                state: { idToken, farmLocations, userData, zones, farm_id },
            });
        } else {
            console.error("Farm ID is undefined. Navigation canceled.");
        }
    };

    const avatarLetter = userData?.name?.[0]?.toUpperCase() || "?";

    if (isMobile) {
        // Render Bottom Navigation on mobile devices
        return (
            <Box sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}>
                <BottomNavigation
                    value={bottomNavValue}
                    onChange={(event, newValue) => {
                        setBottomNavValue(newValue);
                        switch (newValue) {
                            case 0:
                                handleNavigation(`/home`);
                                break;
                            case 1:
                                handleNavigation(`/farm/${farm_id}`);
                                break;
                            case 2:
                                handleNavigation(`/crops/${farm_id}`);
                                break;
                            case 3:
                                handleNavigation(`/financials/${farm_id}`);
                                break;
                            case 4:
                                handleNavigation(`/traceability/${farm_id}`);
                                break;
                            default:
                                break;
                        }
                    }}
                    sx={{ backgroundColor: "#333" }}
                >
                    <BottomNavigationAction label="Map" icon={<MapIcon />} />
                    <BottomNavigationAction label="Dashboard" icon={<BarChartIcon />} />
                    <BottomNavigationAction label="Crops" icon={<SpaIcon />} />
                    <BottomNavigationAction label="Finance" icon={<AttachMoneyIcon />} />
                    <BottomNavigationAction label="Traceability" icon={<ContentPasteSearchIcon />} />
                </BottomNavigation>
            </Box>
        );
    }

    // Render Sidebar on larger screens
    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: "#333",
                width: menuExpanded ? "200px" : "80px",
                height: "100vh",
                transition: "width 0.3s",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "16px",
                left: 0,
            }}
        >
            {/* Menu Toggle Button */}
            <IconButton
                color="inherit"
                aria-label="menu"
                onClick={handleMenuToggle}
                sx={{ marginBottom: "16px" }}
            >
                <MenuIcon />
            </IconButton>

            {/* Map Icon */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: menuExpanded ? "flex-start" : "center",
                    width: "100%",
                    paddingLeft: menuExpanded ? "16px" : 0,
                    marginBottom: "32px",
                }}
            >
                <IconButton
                    color="inherit"
                    aria-label="map"
                    onClick={() => handleNavigation(`/home`)}
                >
                    <MapIcon />
                </IconButton>
                {menuExpanded && (
                    <Typography variant="body1" sx={{ ml: 1 }}>
                        Map
                    </Typography>
                )}
            </Box>

            {/* Grouped Icons */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: menuExpanded ? "flex-start" : "center",
                    width: "100%",
                    gap: 2,
                }}
            >
                {[
                    { icon: <BarChartIcon />, label: "Dashboard", path: `/farm/${farm_id}` },
                    { icon: <SpaIcon />, label: "Crops", path: `/crops/${farm_id}` },
                    { icon: <AttachMoneyIcon />, label: "Finance", path: `/financials/${farm_id}` },
                    { icon: <ContentPasteSearchIcon />, label: "Traceability", path: `/traceability/${farm_id}` },
                ].map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: menuExpanded ? "flex-start" : "center",
                            width: "100%",
                            paddingLeft: menuExpanded ? "16px" : 0,
                        }}
                    >
                        <IconButton
                            color="inherit"
                            aria-label={item.label}
                            onClick={() => handleNavigation(item.path)}
                        >
                            {item.icon}
                        </IconButton>
                        {menuExpanded && (
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                {item.label}
                            </Typography>
                        )}
                    </Box>
                ))}
            </Box>

            {/* User Avatar */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: menuExpanded ? "flex-start" : "center",
                    width: "100%",
                    paddingLeft: menuExpanded ? "16px" : 0,
                    marginTop: "auto",
                    marginBottom: "16px",
                }}
            >
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt="User Avatar">{avatarLetter}</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
                            {/* Menu for Avatar - Opens upwards */}
                <Menu
                    sx={{ mt: '-45px' }}  // Ensure it opens upwards
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'bottom',   // Anchor menu to the bottom of the avatar
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',   // Menu will transform upwards
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem>{userData.name}</MenuItem>
                    {/* Copy ID Token Menu Item */}
                    <MenuItem
                        onClick={() => {
                            handleCopyIdToken();
                            handleCloseUserMenu();
                        }}
                    >
                        <Typography textAlign="center">Copy ID Token</Typography>
                    </MenuItem>

                    {/* Logout Menu Item */}
                    <MenuItem
                        onClick={() => {
                            onLogout();
                            handleCloseUserMenu();
                        }}
                    >
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
        </AppBar>
    );
};

export default FarmSideBar;
