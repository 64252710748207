import React from 'react';
import { Box, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const MetricsCard = ({
    title,
    value,
    change,
    isIncrease,
    icon,
    availableYears,
    selectedYear,
    onYearChange,
}) => {
    return (
        <Box
            sx={{
                backgroundColor: '#1e1e1e',
                color: 'white',
                borderRadius: 2,
                boxShadow: 4,
                padding: 2,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
            {/* Title and Icon */}
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center">
                    {icon}
                    <Typography variant="h6" sx={{ marginLeft: 1 }}>
                        {title}
                    </Typography>
                </Box>
            </Box>

            {/* Value Display */}
            <Typography variant="h3" sx={{ marginTop: 2 }}>
                {value}
            </Typography>

            {/* Change Indicator */}
            <Box display="flex" alignItems="center" mt={2}>
                {isIncrease ? (
                    <TrendingUpIcon sx={{ color: 'green', fontSize: 24, marginRight: 0.5 }} />
                ) : (
                    <TrendingDownIcon sx={{ color: 'red', fontSize: 24, marginRight: 0.5 }} />
                )}
                <Typography variant="h6" fontWeight="bold" color={isIncrease ? 'green' : 'red'}>
                    {change} vs. {selectedYear - 1}
                </Typography>
            </Box>
        </Box>
    );
};

export default MetricsCard;
