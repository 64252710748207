import React, { useState, useMemo } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';
import { Box, Typography, ButtonGroup, Button } from '@mui/material';

const colors = {
    fertilizerCost: '#82ca9d',
    fungicideCost: '#8884d8',
    insecticideCost: '#ff7300',
    herbicideCost: '#ffbb28',
    seedCost: '#a4de6c',
};

const CostsBarChart = ({ data }) => {
    const [viewMode, setViewMode] = useState('yearly'); // 'monthly' or 'yearly'

    // Memoized data based on the selected view mode
    const displayedData = useMemo(() => {
        return viewMode === 'monthly'
            ? data.filter((item) => item.monthYear)
            : data.filter((item) => item.year);
    }, [data, viewMode]);

    return (
        <Box sx={{ backgroundColor: '#1e1e1e', padding: 2, borderRadius: 4 }}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
            >
                <Typography variant="h6" color="white">
                    {viewMode === 'yearly' ? 'Annual Costs by Category' : 'Monthly Costs by Category'}
                </Typography>
                {/* Button Group for Monthly/Yearly Toggle */}
                <ButtonGroup size="small" sx={{ backgroundColor: '#333', borderRadius: 2 }}>
                    <Button
                        variant={viewMode === 'yearly' ? 'contained' : 'outlined'}
                        onClick={() => setViewMode('yearly')}
                        sx={{
                            color: viewMode === 'yearly' ? 'white' : '#ccc',
                            borderColor: 'transparent',
                        }}
                    >
                        Yearly
                    </Button>
                    <Button
                        variant={viewMode === 'monthly' ? 'contained' : 'outlined'}
                        onClick={() => setViewMode('monthly')}
                        sx={{
                            color: viewMode === 'monthly' ? 'white' : '#ccc',
                            borderColor: 'transparent',
                        }}
                    >
                        Monthly
                    </Button>
                </ButtonGroup>
            </Box>
            <ResponsiveContainer width="100%" height={470}>
                <BarChart
                    data={displayedData}
                    margin={{ top: 50, right: 30, left: 20, bottom: 60 }} // Adjusted top margin for legend space
                >
                    <Legend
                        verticalAlign="top" // Position legend above the chart
                        align="center" // Center the legend horizontally
                        wrapperStyle={{
                            fontSize: '0.8rem', // Smaller font size
                            paddingBottom: '10px', // Space between legend and chart
                        }}
                    />
                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                    <XAxis
                        dataKey={viewMode === 'yearly' ? 'year' : 'monthYear'}
                        stroke="#fff"
                        tick={{ fill: '#ccc', fontSize: 12 }}
                        angle={-45}
                        textAnchor="end"
                        interval={0} // Ensure all labels are displayed
                    />
                    <YAxis
                        stroke="#fff"
                        tick={{ fill: '#ccc', fontSize: 12 }}
                        label={{
                            value: 'Cost (NGN)',
                            angle: -90,
                            position: 'insideLeft',
                            textAnchor: 'middle',
                            dx: -10,
                            fill: '#ccc',
                        }}
                    />
                    <Tooltip
                        formatter={(value) => `${value} NGN`}
                        contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                    />
                    <Bar dataKey="fertilizerCost" fill={colors.fertilizerCost} name="Fertilizer Cost" />
                    <Bar dataKey="fungicideCost" fill={colors.fungicideCost} name="Fungicide Cost" />
                    <Bar dataKey="insecticideCost" fill={colors.insecticideCost} name="Insecticide Cost" />
                    <Bar dataKey="herbicideCost" fill={colors.herbicideCost} name="Herbicide Cost" />
                    <Bar dataKey="seedCost" fill={colors.seedCost} name="Seed Cost" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default CostsBarChart;
