import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Tab, Tabs, createTheme, ThemeProvider } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useFarmData } from '../context/FarmDataContext';
import CropTimeline from './CropTimeline';
import EventUsageSummary from './EventUsageSummary';

const CropsPage = () => {
    const { farm_id } = useParams();
    const location = useLocation();
    const idToken = location.state?.idToken;
    const { farmData, fetchFarmData, loading } = useFarmData();
    const [tabValue, setTabValue] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [cropEvents, setCropEvents] = useState([]);

    // Handle row click
    const handleRowClick = (params) => {
        if (selectedRow && selectedRow.id === params.row.id) {
            setSelectedRow(null); // Deselect row
            setSelectedRowId(null); // Clear selected row ID
        } else {
            setSelectedRow(params.row); // Select row
            setSelectedRowId(params.row.id); // Set selected row ID

        }
    };

    // Handle clicking outside the table
    const handleDeselect = (event) => {
        if (!event.target.closest('.MuiDataGrid-root')) {
            setSelectedRow(null); // Deselect row
            setSelectedRowId(null); // Clear selected row ID
        }
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: { main: '#90caf9' },
            secondary: { main: '#f48fb1' },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0b0b0',
            },
        },
    });

    useEffect(() => {
        if (idToken && !farmData) {
            fetchFarmData(farm_id, idToken);
        }
    }, [farm_id, idToken, farmData, fetchFarmData]);

    const rows = useMemo(() => {
        if (!farmData?.crops || !farmData?.harvests || !farmData?.sales) return [];

        return farmData.crops.map((crop) => {
            const cropHarvests = farmData.harvests.filter((harvest) => harvest.crop_id === crop.id);

            const totalYield = cropHarvests.reduce((sum, h) => sum + (h.harvest_amt || 0), 0);
            const totalSales = farmData.sales
                .filter((sale) => cropHarvests.some((h) => h.id === sale.harvest_id))
                .reduce((sum, sale) => sum + (sale.sale_amt || 0), 0);

            const lastHarvestDate = cropHarvests.reduce((latest, h) => {
                const date = new Date(h.date);
                return date > latest ? date : latest;
            }, new Date(crop.planting_date));

            const actualYield = crop.plot?.area
                ? (totalYield / crop.plot.area).toFixed(2)
                : 0;

            const yieldPerPlant = crop.units_planted
                ? (totalYield / crop.units_planted).toFixed(2)
                : 0;

            const duration = crop.transplant_date
                ? Math.ceil(
                    (new Date(lastHarvestDate) - new Date(crop.transplant_date)) /
                    (1000 * 60 * 60 * 24)
                )
                : null;

            return {
                id: crop.id,
                variety_name: crop.variety_name,
                plant_type: crop.plant_type,
                planting_date: crop.planting_date,
                transplant_date: crop.transplant_date,
                last_harvest_date: lastHarvestDate.toISOString().split('T')[0],
                units_planted: crop.units_planted,
                zone_name: crop.zone?.name || '',
                plot_name: crop.plot?.name || '',
                plot_area: crop.plot?.area || 0,
                plot_area_unit: crop.plot?.area_unit || '',
                target_yield: crop.target_yield || 0,
                target_yield_unit_type: crop.target_unit_type || '',
                total_yield: totalYield,
                total_sales: totalSales,
                actual_yield: actualYield,
                yield_per_plant: yieldPerPlant,
                duration,
            };
        });
    }, [farmData]);

    const columns = [
        { field: 'variety_name', headerName: 'Variety Name', width: 150 },
        { field: 'plant_type', headerName: 'Plant Type', width: 150 },
        { field: 'planting_date', headerName: 'Planting Date', width: 150 },
        { field: 'transplant_date', headerName: 'Transplant Date', width: 150 },
        { field: 'last_harvest_date', headerName: 'Last Harvest Date', width: 150 },
        { field: 'units_planted', headerName: 'Units Planted', width: 150 },
        { field: 'zone_name', headerName: 'Zone Name', width: 150 },
        { field: 'plot_name', headerName: 'Plot Name', width: 150 },
        { field: 'plot_area', headerName: 'Plot Area', width: 150 },
        { field: 'plot_area_unit', headerName: 'Plot Area Unit', width: 150 },
        { field: 'target_yield', headerName: 'Target Yield', width: 150 },
        { field: 'target_yield_unit_type', headerName: 'Target Yield Unit', width: 150 },
        { field: 'total_yield', headerName: 'Total Yield', width: 150 },
        { field: 'total_sales', headerName: 'Total Sales', width: 150 },
        { field: 'actual_yield', headerName: 'Actual Yield', width: 150 },
        { field: 'yield_per_plant', headerName: 'Yield Per Plant', width: 150 },
        { field: 'duration', headerName: 'Duration (Days)', width: 150 },
    ];

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getDetailPanelContent = useCallback(
        ({ row }) => {
            const cropId = row.id;

            // Retrieve crop-specific data from farmData
            const crop = farmData.crops.find((c) => c.id === cropId);
            if (!crop) {
                return (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                        <CircularProgress />
                    </Box>
                );
            }

            // Use last_harvest_date directly from the row
            const lastHarvestDate = new Date(row.last_harvest_date);

            // Common filter function for events
            const filterEventsByDate = (events) =>
                events.filter(
                    (event) =>
                        event.plot_id === crop.plot_id &&
                        new Date(event.date) >= new Date(crop.transplant_date) &&
                        new Date(event.date) <= lastHarvestDate
                );

            // Only fetch and set events if they haven't been cached already
            if (!cropEvents[cropId]) {
                const fertilizerEvents = filterEventsByDate(farmData.fertilizerEvents || []);
                const fungicideEvents = filterEventsByDate(farmData.fungicideEvents || []);
                const insecticideEvents = filterEventsByDate(farmData.insecticideEvents || []);
                const herbicideEvents = filterEventsByDate(farmData.herbicideEvents || []);
                const harvests = farmData.harvests.filter((h) => h.crop_id === cropId);
                const sales = farmData.sales.filter((sale) =>
                    harvests.some((harvest) => harvest.id === sale.harvest_id)
                );

                setCropEvents((prevEvents) => ({
                    ...prevEvents,
                    [cropId]: {
                        fertilizerEvents,
                        fungicideEvents,
                        insecticideEvents,
                        herbicideEvents,
                        harvests,
                        sales,
                    },
                }));
            }

            const eventData = cropEvents[cropId] || {};

            const eventColumns = (nameField) => [
                { field: 'id', headerName: 'ID', width: 150 },
                { field: 'date', headerName: 'Date', width: 150 },
                { field: nameField, headerName: 'Product Name', width: 200 },
                { field: 'amt_used', headerName: 'Amount Used', width: 150 },
                { field: 'amt_used_unit_type', headerName: 'Unit Type', width: 150 },
                { field: 'event_cost', headerName: 'Event Cost', width: 150 },
            ];

            const harvestColumns = [
                { field: 'id', headerName: 'Harvest ID', width: 150 },
                { field: 'date', headerName: 'Date', width: 150 },
                { field: 'harvest_amt', headerName: 'Harvest Amount', width: 150 },
                { field: 'harvest_unit_type', headerName: 'Unit Type', width: 150 },
            ];

            const salesColumns = [
                { field: 'id', headerName: 'Sale ID', width: 150 },
                { field: 'date', headerName: 'Date', width: 150 },
                { field: 'sale_amt', headerName: 'Sale Amount', width: 150 },
                { field: 'unit_price', headerName: 'Unit Price', width: 150 },
            ];

            const tabContent = [
                <DataGridPro
                    rows={eventData.fertilizerEvents || []}
                    columns={eventColumns('fertilizer_name')}
                    autoHeight
                    pageSize={10}
                    pagination
                />,
                <DataGridPro
                    rows={eventData.fungicideEvents || []}
                    columns={eventColumns('fungicide_name')}
                    autoHeight
                    pageSize={10}
                    pagination
                />,
                <DataGridPro
                    rows={eventData.insecticideEvents || []}
                    columns={eventColumns('insecticide_name')}
                    autoHeight
                    pageSize={10}
                    pagination
                />,
                <DataGridPro
                    rows={eventData.herbicideEvents || []}
                    columns={eventColumns('herbicide_name')}
                    autoHeight
                    pageSize={10}
                    pagination
                />,
                <DataGridPro
                    rows={eventData.harvests || []}
                    columns={harvestColumns}
                    autoHeight
                    pageSize={10}
                    pagination
                />,
                <DataGridPro
                    rows={eventData.sales || []}
                    columns={salesColumns}
                    autoHeight
                    pageSize={10}
                    pagination
                />,
            ];

            return (
                <Box sx={{ p: 2, bgcolor: darkTheme.palette.background.paper, overflow: 'auto' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
                        <Tab label="Fertilizer Events" />
                        <Tab label="Fungicide Events" />
                        <Tab label="Insecticide Events" />
                        <Tab label="Herbicide Events" />
                        <Tab label="Harvests" />
                        <Tab label="Sales" />
                    </Tabs>
                    <Box sx={{ mt: 2 }}>{tabContent[tabValue]}</Box>
                </Box>
            );
        },
        [farmData, cropEvents, tabValue, handleTabChange, darkTheme]
    );

    const getDetailPanelHeight = useCallback(() => 400, []);

    if (loading || !farmData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: "100%",
                    height: '100vh', // Full viewport height
                    bgcolor: darkTheme.palette.background.default,
                    overflow: 'hidden',
                    transition: 'all 0.3s ease-in-out',
                    overflow: 'auto'
                }}
                onClick={handleDeselect} // Handle deselect when clicking outside
            >
                {/* Container Section (70% Height when a row is selected) */}

                {selectedRow && (
                    <Box
                        sx={{
                            flex: '6', // 70% of the parent height
                            bgcolor: darkTheme.palette.background.paper,
                            p: 2,
                            overflow: 'hidden', // Prevent outer overflow
                            borderBottom: `1px solid ${darkTheme.palette.divider}`,
                            display: 'flex',
                            gap: 2, // Space between the two sections
                        }}
                    >
                        {/* Left Section - CropTimeline */}
                        <Box
                            sx={{
                                flex: 8, // Take 8/12 of the space
                                bgcolor: darkTheme.palette.background.paper,
                                borderRadius: '8px',
                                overflow: 'hidden', // Prevent outer overflow
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    overflowY: 'auto', // Enable vertical scrolling
                                    overflowX: 'hidden',
                                    p: 1,
                                }}
                            >
                                {selectedRow && (
                                    <CropTimeline
                                        units_planted={selectedRow.units_planted}
                                        startDate={selectedRow.transplant_date}
                                        endDate={selectedRow.last_harvest_date}
                                        latitude={farmData.farmDetails.lat || 0}
                                        longitude={farmData.farmDetails.lon || 0}
                                        events={cropEvents[selectedRow.id] || []}
                                    />
                                )}
                            </Box>
                        </Box>

                        {/* Right Section - EventUsageSummary */}
                        <Box
                            sx={{
                                flex: 4, // Take 4/12 of the space
                                bgcolor: darkTheme.palette.background.paper,
                                borderRadius: '8px',
                                overflow: 'hidden', // Prevent outer overflow
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    overflowY: 'auto', // Enable vertical scrolling
                                    overflowX: 'hidden',
                                    p: 1,
                                }}
                            >
                                {selectedRow && (
                                    <EventUsageSummary
                                        events={cropEvents[selectedRow.id] || []}
                                        unitsPlanted={selectedRow.units_planted || 1}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}



                {/* Table Section */}
                <Box
                    sx={{
                        flex: selectedRow ? '4' : '1', // 30% if row selected, 100% otherwise
                        transition: 'flex 0.3s ease-in-out',
                        overflow: 'auto',
                    }}
                >
                    <DataGridPro
                        disableCellSelectionOnClick
                        onRowClick={handleRowClick}
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        getDetailPanelContent={getDetailPanelContent}
                        getDetailPanelHeight={getDetailPanelHeight}
                        slots={{ toolbar: GridToolbar }}
                        pagination
                        selectionModel={selectedRowId ? [selectedRowId] : []} // Explicitly manage row selection
                        sx={{
                            bgcolor: darkTheme.palette.background.default,
                            color: darkTheme.palette.text.primary,
                            '& .MuiDataGrid-columnHeaders': {
                                bgcolor: darkTheme.palette.background.paper,
                            },
                            // Remove cell focus styles
                            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            // Apply primary color only if a row is selected
                            '& .MuiDataGrid-row': {
                                bgcolor: 'inherit', // Default background for non-selected rows
                            },
                            '& .MuiDataGrid-row.Mui-selected': {
                                bgcolor: selectedRowId
                                    ? darkTheme.palette.primary.main // Selected row color
                                    : 'inherit', // Reset if no row is selected
                                color: selectedRowId
                                    ? darkTheme.palette.primary.contrastText
                                    : darkTheme.palette.text.primary,
                            },
                            '& .MuiDataGrid-row.Mui-selected:hover': {
                                bgcolor: selectedRowId
                                    ? darkTheme.palette.primary.dark
                                    : 'inherit', // Reset hover color
                            },
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default CropsPage;
