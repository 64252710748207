import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { Box, Tabs, Tab, useMediaQuery, Select, MenuItem, FormControl, Typography } from '@mui/material';
import ProfitabilityChart from './ProfitabilityChart';
import YieldTrendChart from './YieldTrendChart';
import TabPanel from './TabPanel';
import InputPricesChart from './InputPricesChart';
import HarvestsChart from './HarvestsChart';
import MetricsCard from './MetricsCard';
import CostsBarChart from './CostsBarChart';
import ZoneSelectList from './ZoneSelectList';
import { useFarmData } from '../context/FarmDataContext';

const FarmDetailsPage = () => {
    const location = useLocation();
    const zones = location.state?.zones;
    const { idToken, farm_id } = useOutletContext();
    const { farmData, fetchFarmData, loading } = useFarmData();
    const [salesView, setSalesView] = useState("zone_id");
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedPlantType, setSelectedPlantType] = useState(null); // State for selected plant type
    const [plantTypes, setPlantTypes] = useState([]); // State for plant types
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const isMobile = useMediaQuery('(max-width: 600px)');

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    // Fetch data using the context
    useEffect(() => {
        if (idToken && !farmData) {
            fetchFarmData(farm_id, idToken); // Fetch data via context
        }
    }, [farm_id, idToken, farmData, fetchFarmData]);

    // Extract plant types dynamically
    useEffect(() => {
        if (farmData) {
            const types = Array.from(
                new Set(farmData.harvests.map(h => h.plant_type_cl || h.plant_type || "Unknown"))
            );
            setPlantTypes(types);
            if (!selectedPlantType && types.length > 0) {
                setSelectedPlantType(types[0]); // Default to the first plant type
            }
        }
    }, [farmData, selectedPlantType]);

    const availableYears = Array.from(
        new Set([
            ...farmData.sales.map(sale => new Date(sale.date).getFullYear()),
            ...farmData.harvests.map(harvest => new Date(harvest.date).getFullYear()),
            ...farmData.fertilizerEvents.map(event => new Date(event.date).getFullYear()),
            ...farmData.crops.map(crop => new Date(crop.planting_date).getFullYear())
        ])
    ).sort((a, b) => b - a);

    if (loading || !farmData) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    const generateStackedBarData = () => {
        const data = {};

        // List of event types
        const eventTypes = ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'];

        // Aggregating event costs
        eventTypes.forEach((eventType) => {
            farmData[eventType].forEach((event) => {
                const date = new Date(event.date);
                const year = date.getFullYear();
                const monthYear = `${year}-${String(date.getMonth() + 1).padStart(2, '0')}`;

                if (!data[year]) {
                    data[year] = { year, fertilizerCost: 0, fungicideCost: 0, insecticideCost: 0, herbicideCost: 0, seedCost: 0 };
                }

                if (!data[monthYear]) {
                    data[monthYear] = { monthYear, fertilizerCost: 0, fungicideCost: 0, insecticideCost: 0, herbicideCost: 0, seedCost: 0 };
                }

                const eventCost = event.event_cost || 0;

                if (eventType === 'fertilizerEvents') {
                    data[year].fertilizerCost += eventCost;
                    data[monthYear].fertilizerCost += eventCost;
                } else if (eventType === 'fungicideEvents') {
                    data[year].fungicideCost += eventCost;
                    data[monthYear].fungicideCost += eventCost;
                } else if (eventType === 'insecticideEvents') {
                    data[year].insecticideCost += eventCost;
                    data[monthYear].insecticideCost += eventCost;
                } else if (eventType === 'herbicideEvents') {
                    data[year].herbicideCost += eventCost;
                    data[monthYear].herbicideCost += eventCost;
                }
            });
        });

        // Aggregating seed costs
        farmData.crops.forEach((crop) => {
            const year = new Date(crop.planting_date).getFullYear();
            const monthYear = `${year}-${String(new Date(crop.planting_date).getMonth() + 1).padStart(2, '0')}`;
            const seedCost = (crop.units_planted || 0) * (crop.unit_cost || 0);

            if (!data[year]) {
                data[year] = { year, fertilizerCost: 0, fungicideCost: 0, insecticideCost: 0, herbicideCost: 0, seedCost: 0 };
            }

            if (!data[monthYear]) {
                data[monthYear] = { monthYear, fertilizerCost: 0, fungicideCost: 0, insecticideCost: 0, herbicideCost: 0, seedCost: 0 };
            }

            data[year].seedCost += seedCost;
            data[monthYear].seedCost += seedCost;
        });

        return Object.values(data).sort((a, b) => {
            const aDate = a.year || new Date(a.monthYear).getTime();
            const bDate = b.year || new Date(b.monthYear).getTime();
            return aDate - bDate;
        });
    };

    const calculateTotalCostsAndChange = (year) => {
        const previousYear = year - 1;

        const calculateYearlyCost = (events, targetYear) => {
            return events
                .filter(event => new Date(event.date).getFullYear() === targetYear)
                .reduce((total, event) => total + (event.event_cost || 0), 0);
        };

        const currentYearCost = ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'].reduce(
            (total, eventType) => total + calculateYearlyCost(farmData[eventType], year),
            0
        );

        const lastYearCost = ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'].reduce(
            (total, eventType) => total + calculateYearlyCost(farmData[eventType], previousYear),
            0
        );

        const change = lastYearCost !== 0 ? ((currentYearCost - lastYearCost) / lastYearCost) * 100 : 0;

        return {
            totalCost: currentYearCost.toFixed(2),
            change: change.toFixed(1),
            isIncrease: change >= 0,
            previousYear
        };
    };

    const calculateTotalAndChange = (data, field, year) => {
        const previousYear = year - 1;

        const currentYearTotal = data
            .filter(item => new Date(item.date).getFullYear() === year)
            .reduce((total, item) => total + (item[field] || 0), 0);

        const lastYearTotal = data
            .filter(item => new Date(item.date).getFullYear() === previousYear)
            .reduce((total, item) => total + (item[field] || 0), 0);

        const change = lastYearTotal !== 0 ? ((currentYearTotal - lastYearTotal) / lastYearTotal) * 100 : 0;

        return {
            total: currentYearTotal.toFixed(2),
            change: change.toFixed(1),
            isIncrease: change >= 0,
            previousYear
        };
    };

    const calculatePostHarvestLossAndChange = (harvests, sales, year) => {
        const previousYear = year - 1;

        const yearHarvest = (year) =>
            harvests
                .filter(h => new Date(h.date).getFullYear() === year)
                .reduce((total, h) => total + (h.harvest_amt || 0), 0);

        const yearSalesHarvestAmt = (year) =>
            sales
                .filter(s => new Date(s.date).getFullYear() === year)
                .reduce((total, s) => total + (s.harvest_amt || 0), 0);

        const currentYearLoss = ((yearHarvest(year) - yearSalesHarvestAmt(year)) / yearHarvest(year)) * 100;
        const lastYearLoss = ((yearHarvest(previousYear) - yearSalesHarvestAmt(previousYear)) / yearHarvest(previousYear)) * 100;

        const change = lastYearLoss !== 0 ? ((currentYearLoss - lastYearLoss) / Math.abs(lastYearLoss)) * 100 : 0;

        return {
            totalLoss: currentYearLoss.toFixed(1),
            change: change.toFixed(1),
            isIncrease: change >= 0,
            previousYear
        };
    };

    const totalCosts = calculateTotalCostsAndChange(selectedYear);
    const totalSales = calculateTotalAndChange(farmData.sales, "sale_amt", selectedYear);
    const totalHarvests = calculateTotalAndChange(farmData.harvests, "harvest_amt", selectedYear);
    const postHarvestLoss = calculatePostHarvestLossAndChange(farmData.harvests, farmData.sales, selectedYear);


    const generateProfitabilityData = () => {
        const stackedData = {};
        const eventTypes = ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'];

        // Process sales data
        farmData.sales.forEach(sale => {
            const date = new Date(sale.date);
            const year = date.getFullYear(); // Extract year from sale date

            const harvest = farmData.harvests.find(h => h.id === sale.harvest_id);
            const plantType = harvest?.plant_type_cl || harvest?.plant_type || "Unknown";
            const zoneId = sale.zone_id || "Unknown";
            const zoneName = zones.find(zone => zone.id === zoneId)?.name || "Unknown Zone";

            // Grouping key
            const groupKey = salesView === "zone_id" ? zoneName : plantType;

            if (!stackedData[year]) {
                stackedData[year] = {};
            }

            if (!stackedData[year][groupKey]) {
                stackedData[year][groupKey] = {
                    group: groupKey,
                    year,
                    totalSales: 0,
                    fertilizerCost: 0,
                    fungicideCost: 0,
                    insecticideCost: 0,
                    herbicideCost: 0,
                    seedCost: 0,
                };
            }

            // Sum sales
            stackedData[year][groupKey].totalSales += sale.sale_amt || 0;
        });

        // Process event costs
        eventTypes.forEach(eventType => {
            farmData[eventType].forEach(event => {
                const eventDate = new Date(event.date);
                const year = eventDate.getFullYear(); // Extract year from event date
                const zoneId = event.zone_id || "Unknown";

                // Distinct plant types in the zone
                const plantTypesInZone = Array.from(
                    new Set(
                        farmData.crops
                            .filter(crop => crop.zone_id === zoneId)
                            .map(crop => crop.plant_type_cl || crop.plant_type || "Unknown")
                    )
                );

                if (salesView === "zone_id") {
                    const zoneName = zones.find(zone => zone.id === zoneId)?.name || "Unknown Zone";

                    if (!stackedData[year]) {
                        stackedData[year] = {};
                    }

                    if (!stackedData[year][zoneName]) {
                        stackedData[year][zoneName] = {
                            group: zoneName,
                            year,
                            totalSales: 0,
                            fertilizerCost: 0,
                            fungicideCost: 0,
                            insecticideCost: 0,
                            herbicideCost: 0,
                            seedCost: 0,
                        };
                    }

                    const eventCost = event.event_cost || 0;

                    if (eventType === 'fertilizerEvents') {
                        stackedData[year][zoneName].fertilizerCost += eventCost;
                    } else if (eventType === 'fungicideEvents') {
                        stackedData[year][zoneName].fungicideCost += eventCost;
                    } else if (eventType === 'insecticideEvents') {
                        stackedData[year][zoneName].insecticideCost += eventCost;
                    } else if (eventType === 'herbicideEvents') {
                        stackedData[year][zoneName].herbicideCost += eventCost;
                    }
                }

                if (salesView === "plant_type") {
                    const eventCost = event.event_cost || 0;
                    const costPerPlantType = plantTypesInZone.length > 0 ? eventCost / plantTypesInZone.length : 0;

                    plantTypesInZone.forEach(plantType => {
                        if (!stackedData[year]) {
                            stackedData[year] = {};
                        }

                        if (!stackedData[year][plantType]) {
                            stackedData[year][plantType] = {
                                group: plantType,
                                year,
                                totalSales: 0,
                                fertilizerCost: 0,
                                fungicideCost: 0,
                                insecticideCost: 0,
                                herbicideCost: 0,
                                seedCost: 0,
                            };
                        }

                        if (eventType === 'fertilizerEvents') {
                            stackedData[year][plantType].fertilizerCost += costPerPlantType;
                        } else if (eventType === 'fungicideEvents') {
                            stackedData[year][plantType].fungicideCost += costPerPlantType;
                        } else if (eventType === 'insecticideEvents') {
                            stackedData[year][plantType].insecticideCost += costPerPlantType;
                        } else if (eventType === 'herbicideEvents') {
                            stackedData[year][plantType].herbicideCost += costPerPlantType;
                        }
                    });
                }
            });
        });

        // Process seed costs
        farmData.crops.forEach(crop => {
            const cropYear = new Date(crop.planting_date).getFullYear(); // Extract year from planting date
            const groupKey = salesView === "zone_id"
                ? zones.find(zone => zone.id === crop.zone_id)?.name || "Unknown Zone"
                : crop.plant_type_cl || crop.plant_type || "Unknown";

            if (!stackedData[cropYear]) {
                stackedData[cropYear] = {};
            }

            if (!stackedData[cropYear][groupKey]) {
                stackedData[cropYear][groupKey] = {
                    group: groupKey,
                    year: cropYear,
                    totalSales: 0,
                    fertilizerCost: 0,
                    fungicideCost: 0,
                    insecticideCost: 0,
                    herbicideCost: 0,
                    seedCost: 0,
                };
            }

            const seedCost = (crop.units_planted || 0) * (crop.unit_cost || 0);
            stackedData[cropYear][groupKey].seedCost += seedCost;
        });

        // Convert to array format for the chart
        return Object.keys(stackedData).flatMap(year =>
            Object.values(stackedData[year]).map(item => {
                const totalCosts =
                    item.fertilizerCost +
                    item.fungicideCost +
                    item.insecticideCost +
                    item.herbicideCost +
                    item.seedCost;
                const profitability = item.totalSales
                    ? ((item.totalSales - totalCosts) / item.totalSales) * 100
                    : 0;

                return {
                    ...item,
                    totalCosts,
                    profitability: profitability.toFixed(1),
                };
            })
        );
    };

    const generateHarvestData = (selectedPlantType) => {
        if (!selectedPlantType) {
            console.error("No selectedPlantType provided.");
            return [];
        }

        console.log("Selected Plant Type:", selectedPlantType);
        console.log("Farm Data Sales:", farmData.sales);

        const harvestData = {};

        // Map sales by harvest ID, including unit price and harvest amount
        const salesByHarvestId = farmData.sales.reduce((acc, sale) => {
            const harvest = farmData.harvests.find((h) => h.id === sale.harvest_id);
            if (harvest && (harvest.plant_type_cl || harvest.plant_type) === selectedPlantType) {
                acc[sale.harvest_id] = {
                    unitPrice: sale.unit_price || 0,
                    harvestAmt: sale.harvest_amt || 0,
                };
            }
            return acc;
        }, {});

        console.log("Sales by Harvest ID:", salesByHarvestId);

        // Process sales for the selected plant type
        farmData.sales.forEach((sale) => {
            const harvest = farmData.harvests.find((h) => h.id === sale.harvest_id);
            if (!harvest || (harvest.plant_type_cl || harvest.plant_type) !== selectedPlantType) {
                return; // Skip if the sale's harvest doesn't match the selected plant type
            }

            const date = new Date(sale.date);
            const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;

            if (!harvestData[monthYear]) {
                harvestData[monthYear] = {
                    monthYear,
                    harvestTotal: 0,
                    unitPriceSum: 0,
                    harvestCount: 0,
                };
            }

            const { unitPrice, harvestAmt } = salesByHarvestId[sale.harvest_id] || {};
            harvestData[monthYear].harvestTotal += harvestAmt || 0;
            harvestData[monthYear].unitPriceSum += (unitPrice || 0) * (harvestAmt || 0);
            harvestData[monthYear].harvestCount += harvestAmt || 0;
        });

        console.log("Generated Harvest Data:", harvestData);

        return Object.values(harvestData)
            .map((entry) => ({
                monthYear: entry.monthYear,
                harvestTotal: entry.harvestTotal,
                averageUnitPrice:
                    entry.harvestCount > 0
                        ? (entry.unitPriceSum / entry.harvestCount).toFixed(2)
                        : 0,
            }))
            .sort((a, b) => new Date(a.monthYear) - new Date(b.monthYear));
    };

    const stackedBarData = generateStackedBarData();
    const profitabilityData = generateProfitabilityData();
    //const salesData = generateSalesData();
    const harvestData = selectedPlantType ? generateHarvestData(selectedPlantType) : [];

    // Custom function to handle tab change
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <div className="container-fluid">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2, // Spacing between Typography and Select
                    marginBottom: 2,
                }}
            >
                <Typography variant="h6" sx={{ color: '#fff' }}>
                    Overview
                </Typography>
                <FormControl
                    variant="outlined"
                    sx={{
                        minWidth: 150,
                        backgroundColor: '#2c2c2c', // Dark background
                        borderRadius: 1,
                        '& .MuiOutlinedInput-root': {
                            color: '#fff', // White text color
                            '& fieldset': {
                                borderColor: '#555', // Default border color
                            },
                            '&:hover fieldset': {
                                borderColor: '#888', // Hover border color
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#ffa726', // Mango color when focused
                            },
                        },
                        '& .MuiSelect-icon': {
                            color: '#fff', // Dropdown arrow color
                        },
                        '& .MuiMenuItem-root': {
                            backgroundColor: '#2c2c2c', // Dropdown menu background
                            color: '#fff', // Dropdown menu text
                            '&:hover': {
                                backgroundColor: '#444', // Hover state for menu items
                            },
                        },
                    }}
                >
                    <Select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        displayEmpty
                    >
                        <MenuItem disabled value="">
                            <em>Select Year</em>
                        </MenuItem>
                        {availableYears.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <div className="row g-3 mb-4">
                <div className="col-12 col-md-3">
                    <MetricsCard
                        title="Total Harvests"
                        value={totalHarvests.total}
                        change={`${totalHarvests.change}%`}
                        isIncrease={totalHarvests.isIncrease}
                        icon={<i className="bi bi-leaf"></i>}
                        availableYears={availableYears}
                        selectedYear={selectedYear}
                        onYearChange={handleYearChange}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <MetricsCard
                        title="Total Sales"
                        value={`${totalSales.total}`}
                        change={`${totalSales.change}%`}
                        isIncrease={totalSales.isIncrease}
                        icon={<i className="bi bi-cash"></i>}
                        availableYears={availableYears}
                        selectedYear={selectedYear}
                        onYearChange={handleYearChange}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <MetricsCard
                        title="Total Costs"
                        value={`${totalCosts.totalCost}`}
                        change={`${totalCosts.change}%`}
                        isIncrease={totalCosts.isIncrease}
                        icon={<i className="bi bi-graph-down"></i>}
                        availableYears={availableYears}
                        selectedYear={selectedYear}
                        onYearChange={handleYearChange}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <MetricsCard
                        title="Post-Harvest Loss (%)"
                        value={`${postHarvestLoss.totalLoss}%`}
                        change={`${postHarvestLoss.change}%`}
                        isIncrease={postHarvestLoss.isIncrease}
                        icon={<i className="bi bi-exclamation-circle"></i>}
                        availableYears={availableYears}
                        selectedYear={selectedYear}
                        onYearChange={handleYearChange}
                    />
                </div>
            </div>

            {/* Tabs */}
            <div className="mb-4">
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    TabIndicatorProps={{ style: { backgroundColor: 'grey' } }}
                >
                    <Tab
                        label="Productivity"
                        sx={{ color: tabIndex === 0 ? 'black' : 'grey' }}
                    />
                    <Tab
                        label="Harvests & Prices"
                        sx={{ color: tabIndex === 1 ? 'black' : 'grey' }}
                    />
                    <Tab
                        label="Input Use"
                        sx={{ color: tabIndex === 2 ? 'black' : 'grey' }}
                    />
                </Tabs>
            </div>

            {/* Tab Panels */}
            <TabPanel value={tabIndex} index={0}>
                <div className="row g-3">
                    <div className="col-12 col-md-6">
                        <ProfitabilityChart
                            data={profitabilityData}
                            salesView={salesView}
                            setSalesView={setSalesView}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <YieldTrendChart
                            cropsData={farmData.crops}
                            harvestsData={farmData.harvests}
                        />
                    </div>
                </div>
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
                <div className="row g-3">
                    <div className="col-12">
                        <HarvestsChart
                            data={harvestData}
                            selectedPlantType={selectedPlantType}
                            setSelectedPlantType={setSelectedPlantType}
                            plantTypes={plantTypes}
                        />
                    </div>
                </div>
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>
                <div className="row g-3">
                    <div className="col-12 col-md-6">
                        <CostsBarChart data={stackedBarData} />
                    </div>
                    <div className="col-12 col-md-6">
                        <InputPricesChart inputs={farmData.inputs} />
                    </div>
                </div>
            </TabPanel>

            {/* Zone Select List (Mobile Only) */}
            {isMobile && (
                <Box
                    sx={{
                        position: 'relative',
                        marginTop: 2,
                        backgroundColor: "#1e1e1e",
                        marginBottom: '60px', // Prevent overlap with bottom nav
                        overflowY: 'auto',
                        maxHeight: '300px',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        padding: 2,
                        zIndex: 10, // Ensure it stays above the bottom navigation
                    }}
                >
                    <ZoneSelectList
                        zones={zones}
                        crops={farmData.crops}
                        harvests={farmData.harvests}
                    />
                </Box>
            )}
        </div>
    );
};

export default FarmDetailsPage;
