import React, { useMemo, useState } from 'react';
import {
    Box,
    Tabs,
    Tab,
    ButtonGroup,
    Button,
    Chip,
    Typography,
    createTheme,
    ThemeProvider,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useFarmData } from '../context/FarmDataContext';

const FinancePage = () => {
    const { farmData } = useFarmData();
    const location = useLocation();
    const zones = location.state?.zones || [];
    const [tabIndex, setTabIndex] = useState(0);
    const [viewMode, setViewMode] = useState('yearly'); // 'yearly' or 'monthly'
    const [selectedZones, setSelectedZones] = useState([]); // Multi-select filter state

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: { main: '#90caf9' },
            secondary: { main: '#f48fb1' },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0b0b0',
            },
        },
    });

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleViewModeChange = (mode) => {
        setViewMode(mode);
    };

    const toggleZoneFilter = (zoneId) => {
        setSelectedZones((prev) =>
            prev.includes(zoneId) ? prev.filter((id) => id !== zoneId) : [...prev, zoneId]
        );
    };

    const formatTimeKey = (date) => {
        const d = new Date(date);
        return viewMode === 'yearly' ? d.getFullYear() : `${d.getFullYear()}-${d.getMonth() + 1}`;
    };

    const profitLossData = useMemo(() => {
        if (!farmData) return { rows: [], columns: [] };
    
        const groupedData = {};
        const categories = [
            'Harvest',
            'Sales',
            'Seed Cost',
            'Fertilizer Cost',
            'Fungicide Cost',
            'Insecticide Cost',
            'Herbicide Cost',
            'Gross Profit',
            'Gross Profit %',
        ];
    
        const addRecord = (timeKey, category, amount, record) => {
            if (!groupedData[category]) {
                groupedData[category] = {};
            }
            if (!groupedData[category][timeKey]) {
                groupedData[category][timeKey] = { total: 0, records: [] };
            }
            groupedData[category][timeKey].total += amount;
            groupedData[category][timeKey].records.push(record);
        };
    
        // Process data by category
        farmData.harvests.forEach((harvest) => {
            const timeKey = formatTimeKey(harvest.date);
            addRecord(timeKey, 'Harvest', harvest.harvest_amt || 0, {
                amount: harvest.harvest_amt || 0,
                zone_id: harvest.zone_id,
                plot_id: harvest.plot_id,
            });
        });
    
        farmData.sales.forEach((sale) => {
            const timeKey = formatTimeKey(sale.date);
            addRecord(timeKey, 'Sales', sale.sale_amt || 0, {
                amount: sale.sale_amt || 0,
                zone_id: sale.zone_id,
                plot_id: sale.plot_id,
            });
        });
    
        ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'].forEach((eventType, idx) => {
            const category = categories[3 + idx];
            farmData[eventType].forEach((event) => {
                const timeKey = formatTimeKey(event.date);
                addRecord(timeKey, category, event.event_cost || 0, {
                    amount: event.event_cost || 0,
                    zone_id: event.zone_id,
                    plot_id: event.plot_id,
                });
            });
        });
    
        farmData.crops.forEach((crop) => {
            const timeKey = formatTimeKey(crop.transplant_date || crop.planting_date);
            const seedCost = (crop.units_planted || 0) * (crop.unit_cost || 0);
            addRecord(timeKey, 'Seed Cost', seedCost, {
                amount: seedCost,
                zone_id: crop.zone_id,
                plot_id: crop.plot_id,
            });
        });
    
        // Apply zone filtering for totals
        const rows = categories.map((category, idx) => {
            const row = { id: idx + 1, category };
    
            Object.keys(groupedData[category] || {}).forEach((timeKey) => {
                const records = groupedData[category][timeKey].records;
                const filteredRecords = selectedZones.length
                    ? records.filter((record) => selectedZones.includes(record.zone_id))
                    : records;
    
                row[timeKey] = filteredRecords.reduce((sum, record) => sum + (record.amount || 0), 0);
            });
    
            return row;
        });
    
        // Calculate "Gross Profit" and "Gross Profit %"
        const salesByTime = groupedData['Sales'];
        const costCategories = [
            'Fertilizer Cost',
            'Fungicide Cost',
            'Insecticide Cost',
            'Herbicide Cost',
            'Seed Cost',
        ];
    
        Object.keys(salesByTime || {}).forEach((timeKey) => {
            const salesRecords = groupedData['Sales'][timeKey]?.records || [];
            const filteredSalesRecords = selectedZones.length
                ? salesRecords.filter((record) => selectedZones.includes(record.zone_id))
                : salesRecords;
    
            const totalSales = filteredSalesRecords.reduce((sum, record) => sum + (record.amount || 0), 0);
    
            const totalCosts = costCategories
                .map((category) => {
                    const costRecords = groupedData[category]?.[timeKey]?.records || [];
                    const filteredCostRecords = selectedZones.length
                        ? costRecords.filter((record) => selectedZones.includes(record.zone_id))
                        : costRecords;
    
                    return filteredCostRecords.reduce((sum, record) => sum + (record.amount || 0), 0);
                })
                .reduce((a, b) => a + b, 0);
    
            const grossProfit = totalSales - totalCosts;
            const grossProfitPercentage = totalSales > 0 ? (grossProfit / totalSales) * 100 : 0;
    
            // Add computed values to rows
            const grossProfitRow = rows.find((row) => row.category === 'Gross Profit');
            const grossProfitPercentageRow = rows.find((row) => row.category === 'Gross Profit %');
    
            if (grossProfitRow) grossProfitRow[timeKey] = grossProfit;
            if (grossProfitPercentageRow) grossProfitPercentageRow[timeKey] = grossProfitPercentage;
        });
    
        return { rows, columns: Object.keys(groupedData['Sales'] || []), groupedData };
    }, [farmData, viewMode, selectedZones]);
    

    const renderDetailPanel = ({ row }) => {
        const timeKeys = Object.keys(profitLossData.groupedData[row.category] || {}).sort((a, b) => {
            const [aYear, aMonth] = a.split('-').map(Number);
            const [bYear, bMonth] = b.split('-').map(Number);

            if (aYear !== bYear) {
                return aYear - bYear; // Sort by year
            }
            return (aMonth || 0) - (bMonth || 0); // Sort by month if years are equal
        });

        const records = timeKeys.flatMap(
            (timeKey) => profitLossData.groupedData[row.category]?.[timeKey]?.records || []
        );

        const filteredRecords = selectedZones.length
            ? records.filter((record) => selectedZones.includes(record.zone_id))
            : records;

        const zoneTotals = filteredRecords.reduce((acc, record) => {
            const { zone_id, amount } = record;
            if (!zone_id) return acc;

            if (!acc[zone_id]) {
                acc[zone_id] = {};
            }

            timeKeys.forEach((timeKey) => {
                if (!acc[zone_id][timeKey]) {
                    acc[zone_id][timeKey] = 0;
                }
                if (profitLossData.groupedData[row.category]?.[timeKey]?.records.includes(record)) {
                    acc[zone_id][timeKey] += amount || 0;
                }
            });

            return acc;
        }, {});

        const zoneMap = zones.reduce((acc, zone) => {
            acc[zone.id] = zone.name;
            return acc;
        }, {});

        const rows = Object.entries(zoneTotals).map(([zoneId, totals], index) => ({
            id: index + 1,
            zone_id: zoneMap[zoneId] || `Zone ${zoneId}`,
            ...totals,
        }));

        const columns = [
            { field: 'zone_id', headerName: 'Zone Name', width: 200 },
            ...timeKeys.map((timeKey) => ({
                field: timeKey,
                headerName: timeKey,
                width: 150,
            })),
        ];

        return (
            <Box sx={{ padding: 2, bgcolor: darkTheme.palette.background.paper, color: darkTheme.palette.text.primary }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Zone Totals by Time Period
                </Typography>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    autoHeight
                    slots={{ toolbar: GridToolbar }}
                    pagination
                    sx={{
                        bgcolor: darkTheme.palette.background.default,
                        color: darkTheme.palette.text.primary,
                        '& .MuiDataGrid-columnHeaders': {
                            bgcolor: darkTheme.palette.background.paper,
                            color: darkTheme.palette.text.primary,
                        },
                        '& .MuiDataGrid-cell': {
                            color: darkTheme.palette.text.primary,
                        },
                    }}
                />
            </Box>
        );
    };

    const renderTable = (data) => (
        <Box sx={{ height: 600, mt: 2 }}>
            <DataGridPro
                rows={data.rows}
                columns={[
                    { field: 'category', headerName: 'Category', width: 200 },
                    ...data.columns.map((col) => ({
                        field: `${col}`,
                        headerName: `${col}`,
                        width: 150,
                    })),
                ]}
                sx={{
                    bgcolor: darkTheme.palette.background.default,
                    color: darkTheme.palette.text.primary,
                    '& .MuiDataGrid-columnHeaders': {
                        bgcolor: darkTheme.palette.background.paper,
                        color: darkTheme.palette.text.primary,
                    },
                    '& .MuiDataGrid-cell': {
                        color: darkTheme.palette.text.primary,
                    },
                }}
                autoHeight
                slots={{ toolbar: GridToolbar }}
                pagination
                getDetailPanelContent={renderDetailPanel}
                getDetailPanelHeight={() => 300}
                expandDetailPanelByDefault={false}
            />
        </Box>
    );

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ p: 2, bgcolor: darkTheme.palette.background.default, color: darkTheme.palette.text.primary, height: '100%', width: '100%', overflow: 'auto' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        centered
                        textColor="secondary"
                        indicatorColor="secondary"
                    >
                        <Tab label="Profit & Loss" />
                        <Tab label="Cash Flow Statement" />
                        <Tab label="Balance Sheet" />
                    </Tabs>
                    <ButtonGroup>
                        <Button
                            variant={viewMode === 'yearly' ? 'contained' : 'outlined'}
                            onClick={() => handleViewModeChange('yearly')}
                        >
                            Yearly
                        </Button>
                        <Button
                            variant={viewMode === 'monthly' ? 'contained' : 'outlined'}
                            onClick={() => handleViewModeChange('monthly')}
                        >
                            Monthly
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, overflowX: 'auto', mb: 2 }}>
                    {zones.map((zone) => (
                        <Chip
                            key={zone.id}
                            label={zone.name}
                            color={selectedZones.includes(zone.id) ? 'primary' : 'default'}
                            onClick={() => toggleZoneFilter(zone.id)}
                            clickable
                        />
                    ))}
                </Box>
                {tabIndex === 0 && renderTable(profitLossData)}
            </Box>
        </ThemeProvider>
    );
};

export default FinancePage;
