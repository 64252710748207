import React from 'react';
import { Box, IconButton, AppBar } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ZoneSelectList from './ZoneSelectList';

const ZoneDrawer = ({ zones, crops, harvests, drawerExpanded, setDrawerExpanded }) => {
    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: "#333",
                width: drawerExpanded ? "300px" : "80px",
                height: "100vh",
                transition: "width 0.3s",
                display: "flex",
                flexDirection: "column",
                alignItems: drawerExpanded ? "flex-start" : "center",
                justifyContent: "flex-start",
                right: 0,
                zIndex: 1201,
                paddingTop: "16px",
            }}
        >
            {/* Drawer Toggle Button */}
            <IconButton
                color="inherit"
                aria-label="toggle drawer"
                onClick={() => setDrawerExpanded(!drawerExpanded)}
                sx={{ marginBottom: "16px", alignSelf: drawerExpanded ? "flex-start" : "center" }}
            >
                <DashboardIcon />
            </IconButton>

            {/* Render Zone List */}
            {drawerExpanded && (
                <Box sx={{ width: "100%", overflowY: "auto", padding: "16px" }}>
                    <ZoneSelectList zones={zones} crops={crops} harvests={harvests} />
                </Box>
            )}
        </AppBar>
    );
};

export default ZoneDrawer;
