import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Box,
    Container,
    Button,
    TextField,
    InputAdornment,
    createTheme,
    ThemeProvider,
    useMediaQuery,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const FarmsNav = ({ farmLocations = [], farm_id, idToken }) => {
    const [search, setSearch] = useState('');
    const [filteredFarms, setFilteredFarms] = useState([]);
    const navigate = useNavigate();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#90caf9',
            },
            secondary: {
                main: '#f48fb1',
            },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0b0b0',
            },
        },
    });

    const isMobile = useMediaQuery("(max-width: 600px)");

    // Filter farms based on the search query and reorder them
    useEffect(() => {
        if (farmLocations.length > 0) {
            let visibleFarms = farmLocations.filter((farm) =>
                farm.name.toLowerCase().includes(search.toLowerCase())
            );

            // Reorder to put the selected farm first
            if (farm_id) {
                visibleFarms = [
                    ...visibleFarms.filter((farm) => farm.id === farm_id),
                    ...visibleFarms.filter((farm) => farm.id !== farm_id),
                ];
            }

            setFilteredFarms(visibleFarms);
        }
    }, [search, farmLocations, farm_id]);

    // Navigate to the selected farm's details page
    const handleFarmClick = (farm) => {
        navigate(`/farm/${farm.id}`, {
            state: { idToken, zones: farm.zones, farmLocations, farm_id: farm.id },
        });
    };

    if (isMobile) {
        return (
            <ThemeProvider theme={darkTheme}>
                <AppBar position="static" color="primary">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%', backgroundColor: darkTheme.palette.background.paper }}>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel sx={{ color: darkTheme.palette.text.secondary }}>
                                        Select a Farm
                                    </InputLabel>
                                    <Select
                                        value={farm_id}
                                        onChange={(e) => {
                                            const selectedFarm = farmLocations.find((f) => f.id === e.target.value);
                                            if (selectedFarm) handleFarmClick(selectedFarm);
                                        }}
                                        displayEmpty
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    maxHeight: 300,
                                                    overflowY: 'auto',
                                                },
                                            },
                                        }}
                                        renderValue={(selected) => {
                                            if (!selected) return 'Search and Select a Farm';
                                            const farm = farmLocations.find((f) => f.id === selected);
                                            return farm ? farm.name : 'Search and Select a Farm';
                                        }}
                                        sx={{
                                            backgroundColor: darkTheme.palette.background.paper,
                                            color: darkTheme.palette.text.primary,
                                            borderRadius: '8px',
                                        }}
                                    >
                                        {/* Search Bar Inside Dropdown */}
                                        <Box sx={{ padding: '8px' }}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                placeholder="Search Farms"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon sx={{ color: darkTheme.palette.text.secondary }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    marginBottom: '8px',
                                                    backgroundColor: darkTheme.palette.background.default,
                                                }}
                                            />
                                        </Box>

                                        {/* Farm List */}
                                        {filteredFarms.length > 0 ? (
                                            filteredFarms.map((farm) => (
                                                <MenuItem key={farm.id} value={farm.id}>
                                                    {farm.name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>
                                                <em>No farms found</em>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </ThemeProvider>
        );
    }


    return (
        <ThemeProvider theme={darkTheme}>
            <AppBar position="static" color="primary">
                <Container maxWidth="xl">
                    <Toolbar
                        disableGutters
                    >
                        {/* Scrollable Farm Names */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                marginRight: 2,
                                display: 'flex',
                                overflowX: 'auto',
                                ml: 2,
                                gap: 2,
                                '&::-webkit-scrollbar': {
                                    height: 6,
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: darkTheme.palette.divider,
                                    borderRadius: 3,
                                },
                            }}
                        >
                            {filteredFarms.map((farm) => (
                                <Button
                                    key={farm.id}
                                    onClick={() => handleFarmClick(farm)}
                                    variant={farm.id === farm_id ? 'contained' : 'outlined'}
                                    color={farm.id === farm_id ? 'secondary' : 'inherit'}
                                    sx={{
                                        flexShrink: 0,
                                        whiteSpace: 'nowrap',
                                        textTransform: 'capitalize',
                                        color:
                                            farm.id === farm_id
                                                ? darkTheme.palette.text.primary
                                                : darkTheme.palette.text.secondary,
                                        backgroundColor:
                                            farm.id === farm_id
                                                ? darkTheme.palette.secondary.main
                                                : 'transparent',
                                        '&:hover': {
                                            backgroundColor:
                                                farm.id === farm_id
                                                    ? darkTheme.palette.secondary.dark
                                                    : darkTheme.palette.action.hover,
                                        },
                                    }}
                                >
                                    {farm.name}
                                </Button>
                            ))}
                        </Box>
                        {/* Search Bar */}
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Search Farms"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            sx={{
                                flexShrink: 0,
                                width: '20%',
                                backgroundColor: darkTheme.palette.background.paper,
                                borderRadius: darkTheme.shape.borderRadius,
                                input: {
                                    color: darkTheme.palette.text.primary,
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{ color: darkTheme.palette.text.secondary }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
};

export default FarmsNav;