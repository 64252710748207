import React, { useMemo, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Typography, CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useFarmData } from '../context/FarmDataContext';

const TraceabilityPage = () => {
    const { farm_id } = useParams();
    const location = useLocation();
    const idToken = location.state?.idToken;
    const { farmData, fetchFarmData, loading } = useFarmData();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: { main: '#90caf9' },
            secondary: { main: '#f48fb1' },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0b0b0',
            },
        },
    });

    React.useEffect(() => {
        if (idToken && !farmData) {
            fetchFarmData(farm_id, idToken);
        }
    }, [farm_id, idToken, farmData, fetchFarmData]);

    const getProductName = (event, activityType) => {
        switch (activityType) {
            case 'fertilizer':
                return event.fertilizer_name_cl || event.fertilizer_name || '';
            case 'fungicide':
                return event.fungicide_name || '';
            case 'insecticide':
                return event.insecticide_name || '';
            case 'herbicide':
                return event.herbicide_name || '';
            default:
                return '';
        }
    };

    const salesRows = useMemo(() => {
        if (!farmData?.sales || !farmData?.harvests || !farmData?.crops || !farmData?.fertilizerEvents) return [];

        return farmData.sales.map((sale, index) => {
            const harvest = farmData.harvests.find((h) => h.id === sale.harvest_id);
            const crop = farmData.crops.find((c) => c.id === harvest?.crop_id);

            return {
                id: index + 1,
                sale_id: sale.id,
                harvest_id: sale.harvest_id,
                date: sale.date,
                variety_name: crop?.variety_name_cl || crop?.variety_name,
                plant_type: crop?.plant_type_cl || crop?.plant_type,
                sale_amt: sale.sale_amt,
                sale_unit_type: sale.sale_unit_type,
                harvest_amt: sale.harvest_amt,
                harvest_unit_type: sale.harvest_unit_type,
                unit_price: sale.unit_price,
                zone_name: sale.zone?.name || 'Unknown',
                plot_name: sale.plot?.name || 'Unknown',
                crop_start_date: crop?.planting_date || null,
                harvest_date: harvest?.date || null,
            };
        });
    }, [farmData]);

    const activityColumns = [
        { field: 'id', headerName: 'Activity ID', width: 150 },
        { field: 'activity_type', headerName: 'Activity Type', width: 150 },
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'event_cost', headerName: 'Cost', width: 150 },
        { field: 'product_name', headerName: 'Product Name', width: 150 },
        { field: 'input_id', headerName: 'Input ID', width: 150 },
        { field: 'amt_used', headerName: 'Amount Used', width: 150 },
        { field: 'amt_used_unit_type', headerName: 'Unit Type', width: 150 },
        { field: 'user_id', headerName: 'User ID', width: 150 },
    ];

    const getDetailPanelContent = useCallback(
    ({ row }) => {
        // Step 1: Find the harvest using the row's harvest_id
        const harvest = farmData.harvests.find((h) => h.id === row.harvest_id);
        if (!harvest) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            );
        }

        // Step 2: Find the crop using the harvest's crop_id
        const crop = farmData.crops.find((c) => c.id === harvest.crop_id);
        if (!crop) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            );
        }

        // Use harvest_date directly from the row
        const harvestDate = new Date(row.harvest_date);

        // Common filter function for events
        const filterEventsByDate = (events) =>
            events.filter(
                (event) =>
                    event.plot_id === crop.plot_id &&
                    new Date(event.date) >= new Date(crop.transplant_date) &&
                    new Date(event.date) <= harvestDate
            );

        // Fetch events filtered by date and plot_id
        const fertilizerEvents = filterEventsByDate(farmData.fertilizerEvents || []);
        const fungicideEvents = filterEventsByDate(farmData.fungicideEvents || []);
        const insecticideEvents = filterEventsByDate(farmData.insecticideEvents || []);
        const herbicideEvents = filterEventsByDate(farmData.herbicideEvents || []);

        // Combine all events into one array for rendering
        const activities = [
            ...fertilizerEvents.map((event) => ({
                id: event.id,
                activity_type: 'fertilizer',
                date: event.date,
                event_cost: event.event_cost || 0,
                product_name: getProductName(event, 'fertilizer'),
                input_id: event.input_id || '',
                amt_used: event.amt_used || 0,
                amt_used_unit_type: event.amt_used_unit_type || '',
                user_id: event.user_id || '',
            })),
            ...fungicideEvents.map((event) => ({
                id: event.id,
                activity_type: 'fungicide',
                date: event.date,
                event_cost: event.event_cost || 0,
                product_name: getProductName(event, 'fungicide'),
                input_id: event.input_id || '',
                amt_used: event.amt_used || 0,
                amt_used_unit_type: event.amt_used_unit_type || '',
                user_id: event.user_id || '',
            })),
            ...insecticideEvents.map((event) => ({
                id: event.id,
                activity_type: 'insecticide',
                date: event.date,
                event_cost: event.event_cost || 0,
                product_name: getProductName(event, 'insecticide'),
                input_id: event.input_id || '',
                amt_used: event.amt_used || 0,
                amt_used_unit_type: event.amt_used_unit_type || '',
                user_id: event.user_id || '',
            })),
            ...herbicideEvents.map((event) => ({
                id: event.id,
                activity_type: 'herbicide',
                date: event.date,
                event_cost: event.event_cost || 0,
                product_name: getProductName(event, 'herbicide'),
                input_id: event.input_id || '',
                amt_used: event.amt_used || 0,
                amt_used_unit_type: event.amt_used_unit_type || '',
                user_id: event.user_id || '',
            })),
        ];

            return (
                <Box sx={{ p: 2, bgcolor: darkTheme.palette.background.paper }}>
                    <Typography variant="h6" color="textPrimary">
                        Related Activities
                    </Typography>
                    <Box sx={{ mt: 2, height: '100%', width: '100%', overflow: 'auto'}}>
                        <DataGridPro
                            rows={activities}
                            columns={activityColumns}
                            autoHeight={false}
                            slots={{ toolbar: GridToolbar }}
                            sx={{
                                bgcolor: darkTheme.palette.background.default,
                                color: darkTheme.palette.text.primary,
                                '& .MuiDataGrid-virtualScroller': {
                                    overflow: 'auto',
                                },
                            }}
                        />
                    </Box>
                </Box>
            );
        },
        [farmData, darkTheme]
    );

    const getDetailPanelHeight = useCallback(() => 400, []);

    if (loading || !farmData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ p: 2, bgcolor: darkTheme.palette.background.default, height: '100%', width: '100%', overflow: 'auto' }}>
                <Box sx={{ height: '100%', overflow: 'hidden' }}>
                    <DataGridPro
                        rows={salesRows}
                        columns={[
                            { field: 'sale_id', headerName: 'Sale ID', width: 150 },
                            { field: 'harvest_id', headerName: 'Harvest ID', width: 150 },
                            { field: 'date', headerName: 'Date', width: 150 },
                            { field: 'variety_name', headerName: 'Variety Name', width: 150 },
                            { field: 'plant_type', headerName: 'Plant Type', width: 150 },
                            { field: 'sale_amt', headerName: 'Sale Amount', width: 150 },
                            { field: 'sale_unit_type', headerName: 'Sale Unit Type', width: 150 },
                            { field: 'harvest_amt', headerName: 'Harvest Amount', width: 150 },
                            { field: 'harvest_unit_type', headerName: 'Harvest Unit Type', width: 150 },
                            { field: 'unit_price', headerName: 'Unit Price', width: 150 },
                            { field: 'zone_name', headerName: 'Zone Name', width: 150 },
                            { field: 'plot_name', headerName: 'Plot Name', width: 150 },
                        ]}
                        getDetailPanelContent={getDetailPanelContent}
                        getDetailPanelHeight={getDetailPanelHeight}
                        slots={{ toolbar: GridToolbar }}
                        pagination
                        disableSelectionOnClick
                        sx={{
                            bgcolor: darkTheme.palette.background.default,
                            color: darkTheme.palette.text.primary,
                            '& .MuiDataGrid-columnHeaders': {
                                bgcolor: darkTheme.palette.background.paper,
                                color: darkTheme.palette.text.primary,
                            },
                            '& .MuiDataGrid-cell': {
                                color: darkTheme.palette.text.primary,
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                overflow: 'auto',
                            },
                        }}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default TraceabilityPage;
