import React from 'react';
import { Bar, XAxis, YAxis, Tooltip, Legend, Line, ComposedChart, ResponsiveContainer } from 'recharts';
import { Box, Typography, Select, MenuItem } from '@mui/material';

const barColors = ['#82ca9d']; // Custom color palette for bars
const lineColor = '#ff7300'; // Custom color for line chart

const HarvestsChart = ({ data, selectedPlantType, setSelectedPlantType, plantTypes }) => {
    // Find the max values for better scaling
    const maxHarvestTotal = Math.max(...data.map(d => d.harvestTotal || 0));
    const maxUnitPrice = Math.max(...data.map(d => d.averageUnitPrice || 0));

    return (
        <Box sx={{ backgroundColor: "#1e1e1e", padding: 2, borderRadius: 4 }}>
            <Typography variant="h6" color="white" mb={2}>
                Harvest Totals and Average Unit Prices by Month
            </Typography>
            <Select
                value={selectedPlantType}
                onChange={(e) => setSelectedPlantType(e.target.value)}
                sx={{ marginBottom: 2, color: "white", backgroundColor: "#333", borderRadius: 2 }}
            >
                {plantTypes.map((plantType) => (
                    <MenuItem key={plantType} value={plantType}>
                        {plantType}
                    </MenuItem>
                ))}
            </Select>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart data={data}>
                    {/* X Axis */}
                    <XAxis
                        dataKey="monthYear"
                        stroke="#fff"
                    />
                    {/* Left Y Axis for Harvest Totals */}
                    <YAxis
                        yAxisId="left"
                        stroke="#fff"
                        domain={[0, maxHarvestTotal + maxHarvestTotal * 0.1]} // Add 10% buffer
                        tick={{ fill: '#ccc' }}
                        label={{
                            value: "Harvest Total (kg)",
                            angle: -90,
                            position: 'insideLeft',
                            fill: '#ccc',
                        }}
                    />
                    {/* Right Y Axis for Average Unit Price */}
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        stroke={lineColor}
                        domain={[0, maxUnitPrice + maxUnitPrice * 0.1]} // Add 10% buffer
                        tick={{ fill: lineColor }}
                        label={{
                            value: "Average Unit Price (NGN)",
                            angle: -90,
                            position: 'insideRight',
                            fill: lineColor,
                        }}
                    />
                    {/* Tooltip */}
                    <Tooltip
                        formatter={(value, name) =>
                            name === "Average Unit Price"
                                ? `${value} NGN`
                                : `${value} kg`
                        }
                        contentStyle={{ backgroundColor: "#333", color: "#fff" }}
                    />
                    {/* Legend */}
                    <Legend wrapperStyle={{ color: "#fff" }} />
                    {/* Bar for Harvest Totals */}
                    <Bar
                        yAxisId="left"
                        dataKey="harvestTotal"
                        fill={barColors[0]}
                        name="Harvest Total"
                    />
                    {/* Line for Average Unit Price */}
                    <Line
                        yAxisId="right"
                        dataKey="averageUnitPrice"
                        stroke={lineColor}
                        name="Average Unit Price"
                        dot={{ r: 4 }}
                        activeDot={{ r: 6 }}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default HarvestsChart;
