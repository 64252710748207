import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Optional: Import Bootstrap JS (required for components like modals, tooltips, etc.)
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import App from "./App";

ReactDOM.render(
    <Router>
      <App />
    </Router>,
  document.getElementById("root")
);