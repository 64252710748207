import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    BarChart,
    Bar,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';

// Event type colors
const eventTypeColors = {
    fertilizer: '#82ca9d',
    fungicide: '#8884d8',
    insecticide: '#ffc658',
    herbicide: '#d32f2f',
    harvest: '#4caf50',
    sale: '#ff9800',
    default: '#bdbdbd',
};

// Generate date range array
const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const lastDate = new Date(endDate);

    while (currentDate <= lastDate) {
        dates.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
};

// Custom Weather Tooltip
const WeatherTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div style={{ background: 'black', padding: '10px', border: '1px solid #ccc' }}>
                <p><strong>Date:</strong> {label}</p>
                <p><strong>Max Temp:</strong> {data.temp_max}°C</p>
                <p><strong>Min Temp:</strong> {data.temp_min}°C</p>
                <p><strong>Precipitation:</strong> {data.precipitation} mm</p>
            </div>
        );
    }
    return null;
};

// Custom Event Tooltip
const EventTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div style={{ background: 'black', padding: '10px', border: '1px solid #ccc' }}>
                <p><strong>Date:</strong> {data.date}</p>
                <p><strong>Category:</strong> {data.category}</p>
                {data.details && Object.entries(data.details).map(([key, value]) => (
                    <p key={key}><strong>{key.replace('_', ' ')}:</strong> {value}</p>
                ))}
            </div>
        );
    }
    return null;
};

const CropTimeline = ({ startDate, endDate, latitude, longitude, events, units_planted }) => {
    const [weatherData, setWeatherData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [error, setError] = useState(null);

    console.log('Received events:', events);

    // ✅ Fetch Weather Data
    useEffect(() => {
        const fetchWeatherData = async () => {
            try {
                // Reset weather data and error on new selection
                setWeatherData([]);
                setError(null);
    
                if (!startDate || !endDate || !latitude || !longitude) {
                    throw new Error('Missing required parameters for fetching weather data');
                }
    
                const response = await axios.get('https://archive-api.open-meteo.com/v1/archive', {
                    params: {
                        latitude,
                        longitude,
                        start_date: startDate,
                        end_date: endDate,
                        daily: 'precipitation_sum,temperature_2m_min,temperature_2m_max',
                        timezone: 'auto',
                    },
                });
    
                const { daily } = response.data;
    
                if (!daily || !daily.time) {
                    throw new Error('Invalid historical weather data response');
                }
    
                const combinedWeather = daily.time.map((date, index) => ({
                    date,
                    precipitation: daily.precipitation_sum[index] || 0,
                    temp_min: daily.temperature_2m_min[index] || 0,
                    temp_max: daily.temperature_2m_max[index] || 0,
                }));
    
                setWeatherData(combinedWeather);
            } catch (error) {
                setError(error.message);
            }
        };

        const prepareEventData = () => {
            const dateRange = generateDateRange(startDate, endDate);
            const combinedData = [];

            dateRange.forEach((date) => {
                ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'].forEach((key) => {
                    (events[key] || []).forEach((record) => {
                        if (record.date === date) {
                            const eventType = key.replace('Events', '');
                            const dat = Math.ceil((new Date(record.date) - new Date(startDate)) / (1000 * 60 * 60 * 24));

                            combinedData.push({
                                date,
                                category: eventType,
                                details: {
                                    date: record.date,
                                    product_name: record[`${eventType}_name`] || 'N/A', // Extract event-specific name
                                    amt_used: record.amt_used || 0,
                                    unit: record.amt_used_unit_type || '',
                                    per_plant: (record.amt_used / units_planted).toFixed(4) || 0,
                                    dat: dat >= 0 ? dat : 'N/A',
                                },
                            });
                        }
                    });
                });

                (events.harvests || []).forEach((record) => {
                    if (record.date === date) {
                        combinedData.push({
                            date,
                            category: 'harvest',
                            details: {
                                date: record.date,
                                amt: record.harvest_amt || 0,
                                unit: record.unit_type || '',
                            },
                        });
                    }
                });

                (events.sales || []).forEach((record) => {
                    if (record.date === date) {
                        combinedData.push({
                            date,
                            category: 'sale',
                            details: {
                                date: record.date,
                                harvest_amt: record.harvest_amt || 0,
                                harvest_unit: record.harvest_unit_type || 0,
                                sale_amt: record.sale_amt || 0,
                                sale_unit: record.sale_unit_type || '',
                                unit_price: record.unit_price || 0,
                            },
                        });
                    }
                });
            });

            setEventData(combinedData);
        };

        fetchWeatherData();
        prepareEventData();
    }, [startDate, endDate, latitude, longitude, events]);

    if (error) return <div>Error: {error}</div>;
    if (!weatherData.length) return <div>Loading weather data...</div>;

    return (
        <div>
            {/* Precipitation */}
            <div style={{ height: '200px' }}>
                <h6>Daily Precipitation</h6>
                <ResponsiveContainer>
                    <BarChart data={weatherData} syncId="cropTimeline">
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date"/>
                        <YAxis />
                        <Tooltip content={<WeatherTooltip />} />
                        <Legend />
                        <Bar dataKey="precipitation" fill="#2196f3" />
                    </BarChart>
                </ResponsiveContainer>
            </div>

            {/* Temperature */}
            <div style={{ height: '200px' }}>
                <h6>Daily Temperature</h6>
                <ResponsiveContainer>
                    <LineChart data={weatherData} syncId="cropTimeline">
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip content={<WeatherTooltip />} />
                        <Legend />
                        <Line dataKey="temp_min" stroke="#82ca9d" dot={false} />
                        <Line dataKey="temp_max" stroke="#ff7300" dot={false} />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            {/* Events */}
            <div style={{ height: '300px' }}>
                <h6>Crop Activity History</h6>
                <ResponsiveContainer>
                    <LineChart data={eventData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" domain={[startDate, endDate]} />
                        <YAxis
                            type="category"
                            dataKey="category"
                            tick={{ fontSize: 10 }} // Adjust font size here
                        />

                        <Tooltip content={<EventTooltip />} />
                        <Legend />
                        {Object.keys(eventTypeColors).map((type) => (
                            <Line
                                key={type}
                                dataKey={(entry) => (entry.category === type ? entry.category : null)}
                                stroke={eventTypeColors[type]}
                                dot={{ r: 5, fill: eventTypeColors[type] }}
                                connectNulls={false}
                                name={type.charAt(0).toUpperCase() + type.slice(1)}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default CropTimeline;