import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Card,
    Select,
    MenuItem,
    Typography,
} from '@mui/material';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';

const YieldTrendChart = ({ cropsData, harvestsData }) => {
    const [selectedPlantType, setSelectedPlantType] = useState('');
    const [availablePlantTypes, setAvailablePlantTypes] = useState([]);

    // Extract unique plant types
    useEffect(() => {
        const plantTypes = [...new Set(cropsData.map(crop => crop.plant_type))];
        setAvailablePlantTypes(plantTypes);
        if (plantTypes.length > 0) {
            setSelectedPlantType(plantTypes[0]); // Default to the first plant type
        }
    }, [cropsData]);

    // Prepare the filtered dataset
    const filteredData = useMemo(() => {
        return cropsData
            .filter(crop => crop.plant_type === selectedPlantType)
            .flatMap((crop) => {
                const cropHarvests = harvestsData.filter(h => h.crop_id === crop.id);
                const totalYield = cropHarvests.reduce((sum, h) => sum + (h.harvest_amt || 0), 0);
                const plotArea = crop.plot?.area || 0;

                if (!crop.transplant_date && !crop.planting_date) return [];
                if (plotArea === 0) return [];

                const actualYield = plotArea ? (totalYield / plotArea).toFixed(2) : 0;

                return {
                    date: crop.transplant_date || crop.planting_date, // X-axis
                    actual_yield: parseFloat(actualYield), // Y-axis
                    variety: crop.variety_name,
                };
            })
            .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date
    }, [cropsData, harvestsData, selectedPlantType]);

    const tooltipFormatter = (value, name, props) => {
        const varietyName = props.payload?.variety || 'Unknown Variety';
        return [`${value} (Actual Yield)`, varietyName];
    };

    return (
        <Card
            sx={{
                backgroundColor: '#1e1e1e',
                padding: 2,
                borderRadius: 4,
                boxShadow: 4
            }}
        >
            {/* Header Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" color="white">
                    Yield Trend by Plant Type
                </Typography>
                <Select
                    value={selectedPlantType}
                    onChange={(e) => setSelectedPlantType(e.target.value)}
                    sx={{
                        color: 'white',
                        backgroundColor: '#333',
                        borderRadius: 2,
                    }}
                >
                    {availablePlantTypes.map(plantType => (
                        <MenuItem key={plantType} value={plantType}>
                            {plantType}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            {/* Line Chart */}
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={filteredData}
                    margin={{ top: 20, right: 50, left: 50, bottom: 80 }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                    <XAxis
                        dataKey="date"
                        tick={{ fill: '#ccc', fontSize: 10 }}
                        angle={-45}
                        textAnchor="end"
                        label={{
                            value: 'Transplant/Planting Date',
                            position: 'insideBottom',
                            offset: -60,
                            fill: '#ccc',
                        }}
                    />
                    <YAxis
                        tick={{ fill: '#ccc', fontSize: 10 }}
                        label={{
                            value: 'Actual Yield',
                            angle: -90,
                            position: 'insideLeft',
                            offset: -10,
                            fill: '#ccc',
                        }}
                    />
                    <Tooltip
                        formatter={tooltipFormatter}
                        contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                    />
                    <Line
                        type="monotone"
                        dataKey="actual_yield"
                        stroke="#90caf9"
                        activeDot={{ r: 8 }}
                        dot={{ r: 4 }}
                        name="Actual Yield"
                    />
                </LineChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default YieldTrendChart;
