import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Card,
    Select,
    MenuItem,
    Button,
    ButtonGroup,
    Typography,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts';

const ProfitabilityChart = ({ data, salesView, setSalesView }) => {
    const [selectedYear, setSelectedYear] = useState(null);
    const [availableYears, setAvailableYears] = useState([]);

    // Extract available years from the data
    useEffect(() => {
        const years = Array.from(new Set(data.map(item => item.year))).sort((a, b) => a - b);
        setAvailableYears(years);
        if (!selectedYear && years.length > 0) {
            setSelectedYear(years[years.length - 1]); // Default to the last available year
        }
    }, [data, selectedYear]);

    // Filter data for the selected year
    const filteredData = useMemo(() => {
        if (!selectedYear) return [];
        return data.filter(item => item.year === selectedYear);
    }, [data, selectedYear]);

    // Process data for the chart
    const processedData = useMemo(() => {
        return filteredData.map(item => {
            const totalCosts =
                item.fertilizerCost +
                item.fungicideCost +
                item.insecticideCost +
                item.herbicideCost +
                item.seedCost; // Include seedCost in total costs
            const profitability = item.totalSales
                ? ((item.totalSales - totalCosts) / item.totalSales) * 100
                : 0;
            return {
                ...item,
                totalCosts,
                profitability: profitability.toFixed(1), // Rounded to one decimal
            };
        });
    }, [filteredData]);

    return (
        <Card
            sx={{
                backgroundColor: '#1e1e1e',
                padding: 2,
                borderRadius: 4,
                boxShadow: 4,
            }}
        >
            {/* Header Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" color="white">
                    Sales, Costs, and Profitability by{' '}
                    {salesView === 'zone_id' ? 'Zone' : 'Plant Type'}
                </Typography>
                <ButtonGroup variant="contained" size="small">
                    <Button
                        onClick={() => setSalesView('plant_type')}
                        variant={salesView === 'plant_type' ? 'contained' : 'outlined'}
                    >
                        Plant Type
                    </Button>
                    <Button
                        onClick={() => setSalesView('zone_id')}
                        variant={salesView === 'zone_id' ? 'contained' : 'outlined'}
                    >
                        Zone
                    </Button>
                </ButtonGroup>
                <Select
                    value={selectedYear || ''}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    sx={{
                        color: 'white',
                        backgroundColor: '#333',
                        borderRadius: 2,
                    }}
                >
                    <MenuItem value="">All Years</MenuItem>
                    {availableYears.map(year => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            {/* Bar Chart */}
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={processedData}
                    margin={{ top: 20, right: 50, left: 50, bottom: 80 }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                    <XAxis
                        dataKey="group"
                        tick={{
                            fill: '#ccc',
                            fontSize: 12,
                        }}
                        angle={-45}
                        textAnchor="end"
                    />
                    <YAxis
                        tick={{
                            fill: '#ccc',
                            fontSize: 12,
                        }}
                    />
                    <Tooltip
                        formatter={(value) =>
                            new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'NGN',
                            }).format(value)
                        }
                        contentStyle={{ backgroundColor: '#333', color: '#fff' }}
                    />
                    <Legend
                        wrapperStyle={{
                            color: '#fff',
                            display: 'flex',
                            flexWrap: 'nowrap',
                            justifyContent: 'center',
                            fontSize: '12px',
                        }}
                        verticalAlign="top"
                        height={50}
                    />
                    <Bar dataKey="totalSales" fill="#00c853" name="Sales">
                        <LabelList
                            dataKey="profitability"
                            position="top"
                            formatter={(value) => `${value}%`}
                            fill="#fff"
                            style={{ fontSize: 10 }}
                        />
                    </Bar>
                    <Bar
                        dataKey="fertilizerCost"
                        stackId="costs"
                        fill="#ff5722"
                        name="Fertilizer Cost"
                    />
                    <Bar
                        dataKey="fungicideCost"
                        stackId="costs"
                        fill="#ff9800"
                        name="Fungicide Cost"
                    />
                    <Bar
                        dataKey="insecticideCost"
                        stackId="costs"
                        fill="#ffc107"
                        name="Insecticide Cost"
                    />
                    <Bar
                        dataKey="herbicideCost"
                        stackId="costs"
                        fill="#ffeb3b"
                        name="Herbicide Cost"
                    />
                    <Bar
                        dataKey="seedCost"
                        stackId="costs"
                        fill="#8bc34a"
                        name="Seed Cost"
                    />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default ProfitabilityChart;
