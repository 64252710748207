import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import PhoneSignUp from "./components/PhoneSignUp";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import FarmDetails from "./components/FarmDetails";
import FinancePage from "./components/FinancePage";
import CropsPage from "./components/CropsPage";
import FarmPageLayout from "./components/FarmPageLayout";
import TraceabilityPage from "./components/TraceabilityPage";
import { FarmDataProvider } from "./context/FarmDataContext"; // Import FarmDataProvider
import { LicenseInfo } from '@mui/x-license-pro';

// Debug the license key
console.log('MUI License Key:', process.env.REACT_APP_MUI_LICENSE_KEY);

// Set the license key from the environment variable
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

function App() {
  return (
    <div style={{ height: "100vh", width: "100vw", margin: 0, padding: 0 }}>
      <UserAuthContextProvider>
        <FarmDataProvider>
            <Routes>
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              {/* Centralized FarmPageLayout for farm-related routes */}
              <Route
                element={
                  <ProtectedRoute>
                    <FarmPageLayout />
                  </ProtectedRoute>
                }
              >
                <Route path="/farm/:farm_id" element={<FarmDetails />} />
                <Route path="/financials/:farm_id" element={<FinancePage />} />
                <Route path="/crops/:farm_id" element={<CropsPage />} />
                <Route path="/traceability/:farm_id" element={<TraceabilityPage />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/" element={<PhoneSignUp />} />
            </Routes>
        </FarmDataProvider>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
